import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Modal, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert
} from '@mui/material';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import useFetchUsers from './UsersTwitter';
import { es } from 'date-fns/locale';

function MentionList() {
  const [mentions, setMentions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userMentions, setUserMentions] = useState([]);  
  const [searchClient, setSearchClient] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [mentionSummary, setMentionSummary] = useState({ total: 0, positives: 0, negatives: 0, neutrals: 0 });

  const { usersTwitter, userinfo } = useFetchUsers();

  function formatTweetDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy, hh:mm a', { locale: es });
  }

  function exportToCSV() {
    const csvRows = [];
    const headers = ['Autor', 'Total menciones', 'Menciones Positivas', 'Menciones Negativas', 'Menciones Neutras'];
    csvRows.push(headers.join(','));

    mentions.forEach(mentions => {
      const row = [
        `"${mentions.usuario_twitter}"`,
        mentions.total_menciones,
        mentions.menciones_positivas,
        mentions.menciones_negativas,
        mentions.menciones_neutras
      ];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'menciones.csv';
    link.click();
  }

  function exportToCSVUser() {
    const csvRows = [];
    const headers = ['Cliente', 'Menciones', 'Usuario Mencion', 'Sentimiento', 'Fecha Mencion', 'Link Mencion'];
    csvRows.push(headers.join(','));
    userMentions.forEach(Usermentions => {
      const row = [
        `"${Usermentions.usuario_twitter}"`,
        `"${Usermentions.mencion.replace(/"/g, '""')}"`, 
        `"${Usermentions.user_mencion}"`,
        Usermentions.sentimiento,
        `"${formatTweetDate(Usermentions.fecha_mencion)}"`,
        Usermentions.link_mencion,
      ];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${selectedUser}_menciones.csv`;
    link.click();
  }

  const fetchUserMentionsReset = () => {
    axios.get(`https://opus-twitter.rimgsa.com/menciones_users/${selectedUser}`)
      .then(response => {
        setUserMentions(response.data);
        setStartDate(null);
        setEndDate(null);
        updateMentionSummary(response.data);
      })
      .catch(error => {
        console.error('Error al obtener menciones de usuario:', error);
      });
  };

  //function handleUpdateMentions() {
  //  fetchResetMentions();
  //  setSnackbarMessage('Información actualizada!');
  //  setSnackbarSeverity('success');
  //  setOpenSnackbar(true);
  //}

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  function fetchFilteredMentions() {
    const params = {
      client: searchClient
    };
  
    axios.get('https://opus-twitter.rimgsa.com/mentions', { params })
      .then(response => {
        setMentions(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las menciones filtrados:', error);
      });
  }

  function fetchResetMentions() {
    axios.get('https://opus-twitter.rimgsa.com/mentions')
      .then(response => {
        setMentions(response.data);
        setSearchClient('');
      })
      .catch(error => {
        console.error('Error al obtener las menciones:', error);
      });
  }

  useEffect(() => {
    axios.get('https://opus-twitter.rimgsa.com/mentions')
      .then(response => {
        setMentions(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las menciones generales:', error);
      });
  }, []);

  const handleOpenModal = (usuario_twitter) => {
    setSelectedUser(usuario_twitter);
    fetchUserMentions(usuario_twitter);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setUserMentions([]);
    setStartDate(null);
    setEndDate(null);
    setSelectedUser('');
  };

  const fetchUserMentions = (usuario_twitter) => {
    const params = {
      startDate: startDate,
      endDate: endDate
    };

    axios.get(`https://opus-twitter.rimgsa.com/menciones_users/${usuario_twitter}`, { params })
      .then(response => {
        setUserMentions(response.data);
        updateMentionSummary(response.data);
      })
      .catch(error => {
        console.error('Error al obtener menciones de usuario:', error);
      });
  };

  const applyDateFilter = () => {
    fetchUserMentions(selectedUser);
  };

  const updateMentionSummary = (mentions) => {
    const summary = mentions.reduce((acc, mention) => {
      acc.total += 1;
      if (mention.sentimiento === 'POS') acc.positives += 1;
      if (mention.sentimiento === 'NEG') acc.negatives += 1;
      if (mention.sentimiento === 'NEU') acc.neutrals += 1;
      return acc;
    }, { total: 0, positives: 0, negatives: 0, neutrals: 0 });

    setMentionSummary(summary);
  };

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes('POS')) {
      return { backgroundColor: '#32CD32' }; 
    } else if (sentiment.includes('NEG')) {
      return { backgroundColor: '#FF0000' }; 
    } else if (sentiment.includes('NEU')) {
      return { backgroundColor: '#808080' }; 
    }
    return {};
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    maxHeight: '70vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <div style={{ marginBottom: '20px', marginTop: '20px', padding: '20px' }}>
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={searchClient}
              label="Buscar por cliente"
              onChange={(e) => setSearchClient(e.target.value)}
            >
              {usersTwitter.map(client => (
                <MenuItem key={client} value={client}>{client}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={fetchFilteredMentions}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Filtrar
          </Button>
          <Button
            variant="contained"
            onClick={fetchResetMentions}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Resetear
          </Button>
          <Button
            variant="contained"
            onClick={exportToCSV}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Exportar CSV
          </Button>
          {
          //<Button
          //  variant="contained"
          //  onClick={handleUpdateMentions}
          //  style={{ marginLeft: '10px', marginTop: '20px' }}
          //>
          //</div>  Actualizar
          //</Button>

          }
        </div>
      </LocalizationProvider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="mention table">
          <TableHead>
            <TableRow>
              <TableCell>Usuario de Twitter</TableCell>
              <TableCell align="right">Total Menciones</TableCell>
              <TableCell align="right">Menciones Positivas</TableCell>
              <TableCell align="right">Menciones Negativas</TableCell>
              <TableCell align="right">Menciones Neutras</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mentions.map((mention) => (
              <TableRow key={mention.id_menciones_general}>
                <TableCell component="th" scope="row">{mention.usuario_twitter}</TableCell>
                <TableCell align="right">{mention.total_menciones}</TableCell>
                <TableCell align="right">{mention.menciones_positivas}</TableCell>
                <TableCell align="right">{mention.menciones_negativas}</TableCell>
                <TableCell align="right">{mention.menciones_neutras}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" onClick={() => handleOpenModal(mention.usuario_twitter)}>Ver Menciones</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Detalles de Menciones
          </Typography>
          <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Total Menciones</TableCell>
                  <TableCell>Menciones Positivas</TableCell>
                  <TableCell>Menciones Negativas</TableCell>
                  <TableCell>Menciones Neutras</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{mentionSummary.total}</TableCell>
                  <TableCell>{mentionSummary.positives}</TableCell>
                  <TableCell>{mentionSummary.negatives}</TableCell>
                  <TableCell>{mentionSummary.neutrals}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <div style={{ marginBottom: '20px' }}>
              <DatePicker
                label="Fecha inicio"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="Fecha fin"
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                variant="contained"
                onClick={applyDateFilter}
                style={{ marginLeft: '10px', marginTop: '20px' }}
              >
                Aplicar Filtro de Fecha
              </Button>
              <Button
                variant="contained"
                onClick={fetchUserMentionsReset}
                style={{ marginLeft: '10px', marginTop: '20px' }}
              >
                Resetear
              </Button>
              <Button
                variant="contained"
                onClick={exportToCSVUser}
                style={{ marginLeft: '10px', marginTop: '20px' }}
              >
                Exportar CSV
              </Button>
            </div>
          </LocalizationProvider>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mención</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Sentimiento</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>URL Mencion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userMentions.map((um, index) => (
                  <TableRow key={index}>
                    <TableCell>{um.mencion}</TableCell>
                    <TableCell>{um.user_mencion}</TableCell>
                    <TableCell style={getSentimentStyle(um.sentimiento)}>
                      {um.sentimiento}
                    </TableCell>
                    <TableCell>{formatTweetDate(um.fecha_mencion)}</TableCell>
                    <TableCell>
                      <a href={um.link_mencion} target="_blank" rel="noopener noreferrer">Ver tweet</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default MentionList;

