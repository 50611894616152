import React, { useState, useEffect } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import TweetList from './components/TweetList';
import MentionList from './components/MentionList';
import CreateUser from './components/CretateUser';
import ReportClient from './components/ReportClient';
import MentionsMetrics from './components/MentionsMetrics';
import TweetMetrics from './components/TweetMetrics';

function App() {
  const [currentTab, setCurrentTab] = useState(0);
  const [timer, setTimer] = useState(600); // 2 minutes in seconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => (prevTimer === 0 ? 600 : prevTimer - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <h2>Tiempo restante: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</h2>
      </div>
      <Tabs  value={currentTab} onChange={handleChangeTab} aria-label="simple tabs example" centered>
        <Tab label="Tweets" />
        <Tab label="Menciones" />
        <Tab label="Historial Tweets" />
        <Tab label="Historial Menciones" />
        <Tab label="Reporte" />
        <Tab label="Cliente" />
      </Tabs>
      {currentTab === 0 && <TweetMetrics />}
      {currentTab === 1 && <MentionsMetrics />}
      {currentTab === 2 && <TweetList />}
      {currentTab === 3 && <MentionList />}
      {currentTab === 4 && <ReportClient />}
      {currentTab === 5 && <CreateUser />}
    </Paper>
  );
}

export default App;
