import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Modal, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { format } from 'date-fns';
import useFetchUsers from './UsersTwitter';
import { es } from 'date-fns/locale';

function MentionsMetrics() {
  const [mentionsMetrics, setMentionsMetrics] = useState([]);
  const [originalMetrics, setOriginalMetrics] = useState([]); // Nueva variable para mantener la data original
  const [openModal, setOpenModal] = useState(false);
  const [userMentions, setUserMentions] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { usersTwitter, userinfo } = useFetchUsers();

  useEffect(() => {
    axios.get('https://opus-twitter.rimgsa.com/menciones_metrics')
      .then(response => {
        const metrics = response.data;

        // Crear un Set de usuarios que ya tienen menciones
        const mentionedUsersSet = new Set(metrics.map(m => m.usuario_twitter));

        // Eliminar duplicados en usersTwitter
        const uniqueUsersTwitter = Array.from(new Set(usersTwitter));

        // Combinar los resultados con la lista completa de clientes
        const combinedMetrics = uniqueUsersTwitter.map(user => {
          if (mentionedUsersSet.has(user)) {
            const userMetric = metrics.find(m => m.usuario_twitter === user);
            return {
              ...userMetric,
              hasMentions: true
            };
          } else {
            return {
              usuario_twitter: user,
              total: 0,
              positivas: 0,
              negativas: 0,
              neutras: 0,
              hasMentions: false // Indicar que no tiene menciones
            };
          }
        });

        setMentionsMetrics(combinedMetrics);
        setOriginalMetrics(combinedMetrics); // Guardar la data original
      })
      .catch(error => {
        console.error('Error al obtener las menciones generales:', error);
      });
  }, [usersTwitter]);

  function fetchResetMentionMetrics() {
    setMentionsMetrics(originalMetrics); // Restablecer la data original
    setSearchClient('');
  }

  function fetchMentionsMetricsByUser(user) {
    console.log(user);
    axios.get(`https://opus-twitter.rimgsa.com/menciones_metrics_users/${user}`)
      .then(response => {
        console.log(response.data);
        setUserMentions(response.data);
      })
      .catch(error => {
        console.log("Error al obtener las menciones para metricas por usuario",error);
      });
  }

  const handleOpenModal = (usuario_twitter) => {
    setSelectedUser(usuario_twitter);
    fetchMentionsMetricsByUser(usuario_twitter);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setUserMentions([]);
    setSelectedUser('');
  };

  const fetchFilteredMentionMetrics = () => {
    const filteredMetrics = originalMetrics.filter(m => m.usuario_twitter === searchClient);
    setMentionsMetrics(filteredMetrics);
  };

  function formatTweetDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy, hh:mm a', { locale: es });
  }

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes('POS')) {
      return { backgroundColor: '#32CD32' };
    } else if (sentiment.includes('NEG')) {
      return { backgroundColor: '#FF0000' };
    } else if (sentiment.includes('NEU')) {
      return { backgroundColor: '#808080' };
    }
    return {};
  };

  const getTweetStyle = (mention) => {
    const user = userinfo.find(user => user.usuario_twitter === mention.usuario_twitter);
    if (user) {
      const meetsMentions = mention.total < user.meta_etiquetas;

      if (meetsMentions) {
        return { backgroundColor: '#FB8181' };
      } else {
        return { backgroundColor: '#76F265' };
      }
    }
  };

  function handleUpdateMentionMetrics() {
    fetchResetMentionMetrics();
    setSnackbarMessage('Información actualizada!');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  function exportToCSVMentionsMetrics() {
    const csvRows = [];
    const headers = ['Usuario de Twitter', 'Total Menciones', 'Menciones Positivas', 'Menciones Negativas', 'Menciones Neutras'];
    csvRows.push(headers.join(','));

    mentionsMetrics.forEach(mention => {
      const row = [
        `"${mention.usuario_twitter}"`,
        mention.total,
        mention.positivas,
        mention.negativas,
        mention.neutras
      ];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'MetricasMenciones.csv';
    link.click();
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: '70vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px', marginTop: '20px' }}>
          <FormControl fullWidth style={{ minWidth: '200px' }}>
            <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={searchClient}
              label="Buscar por cliente"
              onChange={(e) => setSearchClient(e.target.value)}
            >
              {usersTwitter.map(client => (
                <MenuItem key={client} value={client}>{client}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              variant="contained"
              onClick={fetchFilteredMentionMetrics}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Filtrar
            </Button>
            <Button
              variant="contained"
              onClick={fetchResetMentionMetrics}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Resetear
            </Button>
            <Button
              variant="contained"
              onClick={exportToCSVMentionsMetrics}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Exportar CSV
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateMentionMetrics}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Actualizar
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="metrics table">
          <TableHead>
            <TableRow>
              <TableCell>Usuario de Twitter</TableCell>
              <TableCell align="right">Total Menciones</TableCell>
              <TableCell align="right">Menciones Positivas</TableCell>
              <TableCell align="right">Menciones Negativas</TableCell>
              <TableCell align="right">Menciones Neutras</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mentionsMetrics.map((mention) => (
              <TableRow key={mention.usuario_twitter} style={getTweetStyle(mention)}>
                <TableCell component="th" scope="row">{mention.usuario_twitter}</TableCell>
                <TableCell align="right">{mention.total}</TableCell>
                <TableCell align="right">{mention.positivas}</TableCell>
                <TableCell align="right">{mention.negativas}</TableCell>
                <TableCell align="right">{mention.neutras}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => handleOpenModal(mention.usuario_twitter)}
                    disabled={!mention.hasMentions}
                  >
                    Ver Menciones
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Detalles de Menciones de {selectedUser}
          </Typography>
          <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mención</TableCell>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Sentimiento</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>URL Mención</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userMentions.map((um, index) => (
                  <TableRow key={index}>
                    <TableCell>{um.mencion}</TableCell>
                    <TableCell>{um.user_mencion}</TableCell>
                    <TableCell style={getSentimentStyle(um.sentimiento)}>
                      {um.sentimiento}
                    </TableCell>
                    <TableCell>{formatTweetDate(um.fecha_mencion)}</TableCell>
                    <TableCell>
                      <a href={um.link_mencion} target="_blank" rel="noopener noreferrer">Ver tweet</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default MentionsMetrics;
